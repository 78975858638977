@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto-Thin";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Roboto/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Italic";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Roboto/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Extra-Bold";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Roboto/Roboto-Black.ttf");
}

@font-face {
  font-family: "Raleway-Thin";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Raleway/static/Raleway-Thin.ttf");
}

@font-face {
  font-family: "Raleway-ThinItalic";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Raleway/static/Raleway-ThinItalic.ttf");
}

@font-face {
  font-family: "Raleway-Regular";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Raleway/static/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway-Medium";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Raleway/static/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Raleway/static/Raleway-Bold.ttf");
}

@font-face {
  font-family: "Raleway-Semi-Bold";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Raleway/static/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Raleway-Extra-Bold";
  src: url("../public/Resources/Fonts/Raleway,Roboto/Raleway/static/Raleway-ExtraBold.ttf");
}

@layer components {
  /* header */
  .header {
    @apply bg-primary text-center text-5xl py-6 md:py-10 tracking-wider font-robotoExtraBold text-white;
  }

  /* navigation */
  .nav-menu {
    @apply md:flex md:flex-row md:justify-center md:space-x-14 lg:space-x-32;
  }

  .nav-active {
    @apply text-primary hover:text-tertiary active:text-tertiary focus:text-tertiary active:no-underline focus:no-underline hover:no-underline;
  }
  .nav-item {
    @apply cursor-pointer p-2;
  }
  .nav-sub-menu {
    @apply p-2 cursor-pointer text-primary hover:text-tertiary hover:no-underline;
  }

  /* panels */
  .panel-container {
    @apply md:flex flex-row justify-evenly text-white w-full divide-y-4 md:divide-x-4 md:divide-y-0 lg:divide-x-8 divide-tertiary border-4 lg:border-8 border-tertiary;
  }

  .panel {
    @apply md:w-1/3 md:h-48 h-32 flex col-start-2 col-end-6 justify-center items-center cursor-pointer transition duration-300  bg-cover bg-center hover:grayscale focus:grayscale;
  }

  .panel-text {
    @apply text-white hover:no-underline font-robotoExtraBold text-2xl lg:text-4xl text-outline text-center;
  }

  /* donation on home page  */
  .home-donate-container {
    @apply md:grid grid-cols-3 w-full h-full;
  }

  .home-donate {
    @apply col-start-1 col-end-2 w-full flex flex-col justify-center p-4 md:p-2 bg-primary md:bg-none space-y-2  lg:space-y-10;
  }

  .home-donate-item {
    @apply text-center w-full;
  }

  .home-donate-btns {
    @apply m-2 flex flex-col items-center text-white hover:text-primary lg:space-y-8;
  }

  .home-donate-btn {
    @apply bg-tertiary rounded-sm text-white p-4 my-2 w-1/2 font-ralewaySemiBold text-2xl md:text-xl lg:text-2xl;
  }
  .home-donate-link {
    @apply hover:text-primary hover:no-underline text-center w-full;
  }

  /* contact on home page */

  .home-contact-container {
    @apply bg-black w-full text-center p-8 text-tertiary;
  }

  .hc-header {
    @apply text-primary text-2xl font-ralewayExtraBold;
  }

  .hc-info {
    @apply mt-6;
  }

  .hc-text {
    @apply font-robotoRegular text-lg;
  }

  .hc-strong-text {
    @apply font-robotoBold;
  }

  /* niwt footer stamp */
  .designer-info {
    @apply bg-primary text-black w-full text-center text-lg font-ralewayBold p-2;
  }

  .designer-info-link {
    @apply underline;
  }

  /* contact form */

	.cf-container{
		@apply md:flex flex-col items-center justify-center md:w-1/2
	}

	.contact-col{
		@apply flex flex-col md:flex-row justify-start items-center w-3/4 md:w-auto;
	}
  .form-container {
    @apply flex flex-col py-5 items-center justify-center font-ralewayRegular ;
  }

  .form-header {
    @apply text-center font-ralewayExtraBold text-3xl text-primary m-2 lg:text-4xl;
  }

  .form-input {
    @apply border-primary border w-2/3 m-2 p-2 rounded md:text-base md:m-4;
  }

  .form-btn {
    @apply bg-primary rounded py-2 px-3 m-2 text-white font-ralewayBold text-lg w-2/3 md:text-2xl;
  }

  /* history and mission */

  .history-info {
    @apply p-6 font-robotoRegular;
  }

  .history-img {
    @apply md:hidden;
  }

  .history-subheader {
    @apply m-2 font-robotoBold text-4xl;
  }

  .founder-container {
    @apply bg-white flex flex-col items-center font-robotoRegular p-6;
  }

  .founder-info-container {
    @apply md:grid grid-cols-3 items-center;
  }
  .founder-img-container {
    @apply flex flex-col items-center;
  }
  .founder-img {
    @apply rounded-full h-40;
  }
  .founder-img-title {
    @apply text-xl font-robotoRegItalic text-primary m-2;
  }
  .founder-info {
    @apply m-2 col-start-2 col-end-4 leading-loose;
  }

  .mission-container {
    @apply bg-primary md:border-x-4 border-tertiary text-white flex flex-col font-robotoRegular items-center px-6 py-10 text-lg;
  }

  /*what we do */

  .wwd-container {
    @apply p-6 font-robotoRegular lg:w-2/3;
  }
  .wwd-ul {
    @apply list-disc m-4 list-inside;
  }

  /*staffing page*/

  .avatars {
    @apply flex flex-wrap justify-around lg:w-2/3;
  }
  .avatar-intro {
    @apply m-2 p-2 font-robotoRegular lg:w-2/3;
  }
  .avatar-container {
    @apply flex flex-col items-center m-4 p-4;
  }
  .avatar-img {
    @apply rounded-xl h-56;
  }
  .avatar-name {
    @apply text-primary font-robotoBold text-base;
  }
  .avatar-title {
    @apply font-ralewayRegular;
  }

  /* donate page */

  .donate {
    @apply md:flex flex-col relative overflow-hidden;
  }

  .donation-card-container {
    @apply flex flex-col m-4 justify-center items-center relative;
  }
  .dc-info-container {
    @apply lg:w-1/2 dci-transition form-block absolute opacity-0 invisible translate-x-[1000px];
  }
  .dc-info-img {
    @apply rounded-t-lg max-w-md self-center;
  }
  .dc-info-progress {
    @apply flex flex-col items-center m-2 p-2;
  }
  .dc-info.prog-header {
    @apply font-ralewayMedium;
  }
  .dc-info-prog-bar {
  }
  .dc-info-para {
    @apply font-ralewayRegular mx-2 px-4;
  }
  .dc-info-btn-one {
    @apply font-robotoBold rounded-xl bg-primary text-white py-2 px-4 m-4;
  }

  .donation-card {
    @apply dc-transition bg-donation-card h-52 w-[335px] grid bg-no-repeat grid-cols-10 grid-rows-6 text-white font-ralewaySemiBold;
  }
  .dc-header {
    @apply mt-2 col-start-2 col-end-5 row-start-1 row-end-3 font-ralewayExtraBold text-lg tracking-wide justify-self-center self-center;
  }
  .dc-icon {
    @apply col-start-9 row-start-1 row-end-3 self-center cursor-pointer hover:text-tertiary transition duration-500;
  }
  .dc-svg {
    @apply col-start-1 col-end-6 row-start-3 justify-self-center;
  }
  .dc-target-container {
    @apply col-start-7 col-end-11 row-start-4 row-end-6 self-end;
  }
  .dc-target-header {
  }
  .dc-target-amount {
    @apply font-ralewayExtraBold text-lg;
  }

  .donation-options {
    @apply m-10 md:w-2/3 self-center;
  }
  .donation-header {
    @apply underline font-ralewaySemiBold text-xl md:text-2xl text-primary text-center m-4 md:my-10;
  }
  .options-list {
    @apply flex flex-wrap justify-around m-4;
  }
  .option-bg {
    @apply cursor-pointer bg-gradient-to-b from-primary to-tertiary rounded-lg m-2 h-32 w-32 md:h-40 md:w-40 flex hover:bg-tertiary;
  }
  .option-container {
    @apply m-0.5 rounded-lg bg-white flex flex-col items-center justify-center p-2 w-full space-y-2 hover:bg-tertiary transition duration-500;
  }
  .option-icon {
    @apply text-primary;
  }
  .option-title {
    @apply w-full text-center font-robotoThin text-primary text-lg;
  }

  /* donation forms */
  .form-block {
    @apply df-transition translate-y-[1000px] absolute top-0 flex bg-white flex-col rounded-lg dc-info-box-shadow m-4 p-4 pb-6 opacity-0 invisible md:self-center md:w-1/3;
  }
  .form-head-container {
    @apply flex flex-col bg-white items-center;
  }
  .form-icon {
    @apply text-primary self-end m-2 cursor-pointer;
  }
  .form-logo {
    @apply h-28;
  }
  .form-intro {
    @apply font-robotoRegular text-base;
  }
  .df-list-item {
    @apply m-4 text-lg;
  }
  .df-header {
    @apply font-robotoBold text-xl md:text-lg underline text-primary;
  }
  .form-text-container {
    @apply m-2;
  }
	.form-group{
		@apply flex flex-col w-full items-center m-2 md:m-1;
	}
  .form-text {
    @apply font-robotoRegular;
  }
  .form-label {
    @apply font-robotoMedium text-primary;
  }
	.form-select{
		@apply my-2 p-2 bg-white border border-primary rounded;
	}
  .form-note {
    @apply text-xs;
  }
	.form-error{
		@apply text-red-500;
	}
	.form-checkbox{
		@apply mr-2;
	}
}

@layer base {
  .text-outline {
    -webkit-text-stroke: 0.25px black;
  }
  .dc-info-box-shadow {
    box-shadow: 4px 4px 8px rgba(71, 167, 178, 0.51);
    -webkit-box-shadow: 4px 4px 8px rgba(71, 167, 178, 0.51);
  }
  .dc-transition {
    transition: opacity 0.2s, transform 1s, position 1s, visibility 0.5s;
  }
  .dc-transition-props {
    opacity: 0 !important;
    transform: translateX(1000px) !important;
    position: absolute !important;
    visibility: hidden !important;
  }
  .dci-transition {
    transition: opacity 0.2s, transform 1s, position 1s, visibility 1s;
  }
  .dci-transition-props {
    opacity: 1 !important;
    transform: translateX(0px) !important;
    position: relative !important;
    visibility: visible !important;
  }
  .df-transition {
    transition: opacity 1s, transform 1s, visibility 1s;
  }
  .df-transition-props {
    
    opacity: 1 !important;
    transform: translateY(0px) !important;
    visibility: visible !important;
  }
	.nav-transition {
    transition: opacity 10ms, transform 1s, position 555ms, visibility 1s;
  }
  .nav-transition-props {
    opacity: 1 !important;
    transform: translateY(0px) !important;
    position: relative !important;
    visibility: visible !important;
  }
}
